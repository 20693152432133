import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAppState, LoginRequest, login, OptionalApplicationFeature} from 'ngx-esprio-shared';
import { LoginFormValue } from '../login-form/login-form-value.model';
import { environment } from 'projects/nutrition/src/environments/environment';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
})
export class LoginPageComponent implements OnInit {
  public consultantConnectionDisabled: boolean;
  readonly asyncProcessKeys = [login.type];

  constructor(
    public store: Store<BaseAppState>,
    ) {}

  ngOnInit() {
    this.consultantConnectionDisabled = environment.disabledAppFeatures.includes(OptionalApplicationFeature.ConsultantConnection);
  }

  handleLoginClick(formValue: LoginFormValue): void {
    const request: LoginRequest = {
      email: formValue.emailAddress,
      password: formValue.password,
    };
    this.store.dispatch(login({ payload: request }));
  }

  handleLogInMonitoringApp(event: Event) {
    event.preventDefault();
    window.location.href = `${environment.nutritionMonitoringApp}/auth/login`;
  }
}
