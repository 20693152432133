import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '../../environments/environment';
import { BaseAppState, localStorageSyncReducer, logoutResetReducer } from 'ngx-esprio-shared';

export const reducers: ActionReducerMap<BaseAppState> = {};

export const metaReducers: Array<MetaReducer<BaseAppState>> = !environment.production
  ? [logoutResetReducer, storeFreeze, localStorageSyncReducer]
  : [logoutResetReducer, localStorageSyncReducer];
